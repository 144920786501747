import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'
export const useAssistantChat = (conversationId) => {
    const chatWindow = ref(null)
    const containerRef = ref(null)
    const queryClient = useQueryClient()
    const unrefConversationId = unref(conversationId)

    const { execute } = useApi('api/assistant/messages', 'GET')

    const addMessages = (messages, conversationId) => {
        queryClient.setQueryData(['assistant-chat', conversationId], (data) => {
            if (!data)
                return {
                    pages: [
                        {
                            data: messages.data,
                        },
                    ],
                }

            const lastPage = data.pages.length - 1

            return {
                pages: data.pages?.map((page, index) => {
                    if (index === lastPage) {
                        return {
                            ...page,
                            data: [...page.data, ...messages.data],
                        }
                    }

                    return page
                }),
                pageParam: data.pageParam,
            }
        })
    }

    const appendMessage = (text, conversationId, messageId) => {
        if (!text) return
        queryClient.setQueryData(['assistant-chat', conversationId], (data) => {
            if (!data) {
                return {
                    pages: [
                        {
                            data: [
                                {
                                    id: messageId,
                                    role: 'user',
                                    content: text,
                                },
                            ],
                        },
                    ],
                }
            }

            const lastPage = data.pages.length - 1

            return {
                pages: data.pages?.map((page, index) => {
                    if (index === lastPage) {
                        return {
                            ...page,
                            data: page.data.map((x) => {
                                if (x.id === messageId) {
                                    x.loading = false
                                    return {
                                        ...x,
                                        content: (x.content += text),
                                    }
                                }

                                return x
                            }),
                        }
                    }

                    return page
                }),
                pageParam: data.pageParam,
            }
        })
    }

    const {
        data,
        isFetching,
        fetchNextPage,
        hasNextPage: hasMore,
    } = useInfiniteQuery({
        queryKey: ['assistant-chat', unrefConversationId],
        queryFn: ({ pageParam = {} }) => {
            if (!unrefConversationId)
                return new Promise((resolve) =>
                    resolve({
                        data: [],
                    })
                )

            return execute({
                queries: {
                    'filter[conversation_id]': unrefConversationId,
                    limit: 20,
                    ...pageParam,
                },
            })
        },
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (res) => {
            return uniqBy(
                res?.pages?.flatMap((x) => x?.data),
                (x) => x?.id
            )
        },
        refetchOnWindowFocus: false,
        refetchInterval: false,
        staleTime: Infinity,
    })

    const loadMore = () => {
        if (hasMore.value) {
            fetchNextPage()
        }
    }

    const scrollToBottom = () => {
        setTimeout(() => {
            chatWindow.value.scrollTo(0, chatWindow.value.scrollHeight)
        }, 2)
    }

    return {
        loadMore,
        data,
        isFetching,
        hasMore,
        chatWindow,
        containerRef,
        scrollToBottom,
        addMessages,
        appendMessage,
    }
}
